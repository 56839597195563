import {
  FormSubmit,
  SelectOption,
  ValidatedInput,
  ValidatedSelect,
} from 'components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { SkuProduct } from 'models';
import {
  Brick,
  BrickOptions,
  BrickOptionsResult,
  calcModalStyles,
  calculatePriceWall,
  getBrickOptions,
  PriceWall,
  PriceWallResult,
  ThermalInsulationOptions,
  useGetWallPriceHook,
  WallPriceCalculatorResult,
} from 'modules/calculator';
import React, { Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { calculatorStyles } from 'styles';
import { formatPrice } from 'utils';

interface WallProduct {
  id: string;
  sku: SkuProduct;
}

const PriceCalculator: React.FC = () => {
  const { formatMessage } = useIntl();
  const [data, setData] = useState<PriceWall | null>(null);
  const [calcResult, setCalcResult] = useState<PriceWallResult | null>(null);
  const [brickOptionResults, setBrickOptionResults] =
    useState<BrickOptionsResult | null>(null);
  const [triggerReset, setTriggerReset] = useState(false);
  const [isBrickIzoOrProfi, setIsBrickIzoOrProfi] = useState(false);

  const allWallPrices = useGetWallPriceHook();

  const methods = useForm<PriceWall>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const showExtraGlueInfo = isBrickIzoOrProfi
    ? calcModalStyles.infoTextVisible
    : calcModalStyles.infoTextHidden;

  return (
    <div css={calculatorStyles.calculatorConatiner}>
      <h2 css={calculatorStyles.title}>
        <FormattedMessage id="calculator.price.title" />
      </h2>

      {calcResult ? (
        <WallPriceCalculatorResult data={calcResult} />
      ) : (
        <Fragment>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div css={calculatorStyles.calculatorConatiner}>
                <div css={calcModalStyles.root}>
                  <ValidatedSelect
                    name="brick"
                    label={formatMessage({
                      id: 'calculator.price.brick.select',
                    })}
                    options={BrickOptions}
                    getDropdownValue={getBrickValue}
                    required
                  />
                  <ValidatedSelect
                    name="brickPrice"
                    defaultValue={data ? data.brickPrice : undefined}
                    label={formatMessage({
                      id: 'calculator.price.brick.price',
                    })}
                    options={brickOptionResults?.brickPrice || []}
                    triggerReset={triggerReset}
                    isDisabled={!brickOptionResults}
                    required
                  />
                  <div css={showExtraGlueInfo}>
                    <p>
                      <FormattedMessage id="calculator.price.brick.extra" />
                    </p>
                  </div>
                  <div css={showExtraGlueInfo} />
                  <div>
                    <ValidatedSelect
                      name="mort"
                      defaultValue={data ? data.mort : undefined}
                      label={formatMessage({
                        id: 'calculator.price.brick.mort',
                      })}
                      options={brickOptionResults?.mort || []}
                      isDisabled={!brickOptionResults}
                      triggerReset={triggerReset}
                      required
                    />
                  </div>
                  <ValidatedSelect
                    name="plaster"
                    defaultValue={data ? data.plaster : undefined}
                    label={formatMessage({ id: 'calculator.price.plaster' })}
                    options={brickOptionResults?.plaster || []}
                    isDisabled={!brickOptionResults}
                    triggerReset={triggerReset}
                    required
                  />
                  <div css={calcModalStyles.fullWidth}>
                    <ValidatedSelect
                      name="thermalInsulation"
                      defaultValue={data ? data.thermalInsulation : undefined}
                      label={formatMessage({
                        id: 'calculator.price.thermal-insulation',
                      })}
                      options={ThermalInsulationOptions}
                      required
                    />
                  </div>
                  <div>
                    <ValidatedInput
                      name="brickWorkPrice"
                      defaultValue={data ? data.brickWorkPrice : undefined}
                      label={formatMessage({
                        id: 'calculator.price.brick-work-price',
                      })}
                      size="small"
                      placeholder={formatMessage({
                        id: 'calculator.price.brick-work-price',
                      })}
                      validationObject={{
                        required: formatMessage({
                          id: 'shared.validation.required',
                        }),
                        pattern: {
                          value: /^\d+(\.\d+)?$/,
                          message: formatMessage({
                            id: 'shared.validation.decimal-number',
                          }),
                        },
                      }}
                    />
                    <p css={calcModalStyles.infoText}>
                      <FormattedMessage id="calculator.price.brick-work-price.extra" />
                    </p>
                  </div>
                  <div>
                    <ValidatedInput
                      name="constructionFactor"
                      defaultValue={data ? data.constructionFactor : undefined}
                      label={formatMessage({
                        id: 'calculator.price.construction-factor.label',
                      })}
                      size="small"
                      placeholder={formatMessage({
                        id: 'calculator.price.construction-factor.placeholder',
                      })}
                      validationObject={{
                        validate: (data: string) => {
                          if (
                            parseFloat(data) < 1.5 ||
                            parseFloat(data) > 4.5
                          ) {
                            return formatMessage({
                              id: 'calculator.price.construction-factor.value',
                            });
                          }
                          return;
                        },
                        required: formatMessage({
                          id: 'shared.validation.required',
                        }),
                        pattern: {
                          value: /^\d+(\.\d+)?$/,
                          message: formatMessage({
                            id: 'shared.validation.decimal-number',
                          }),
                        },
                      }}
                    />
                    <p css={calcModalStyles.infoText}>
                      <FormattedMessage id="calculator.price.construction-factor.extra" />
                    </p>
                  </div>
                </div>
              </div>
              <div css={calculatorStyles.calculatorConatiner}>
                <FormSubmit text={formatMessage({ id: 'shared.calculate' })} />
              </div>
            </form>
          </FormProvider>
        </Fragment>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        style={{
          width: '70%',
        }}
        toastStyle={{
          borderRadius: '4px',
        }}
      />
    </div>
  );

  function onSubmit(data: PriceWall) {
    setData(data);
    setCalcResult(calculatePriceWall(data));
  }

  function getBrickValue(value: SelectOption<Brick>) {
    const chosenProduct = allWallPrices.filter(
      (item: WallProduct) => item.id === value.id,
    )[0];

    if (value.value.includes('profi') || value.value.includes('izo')) {
      setIsBrickIzoOrProfi(true);
    } else {
      setIsBrickIzoOrProfi(false);
    }

    const minPiecesPerMsq =
      chosenProduct.sku?.salesRelation.piecesMax ||
      chosenProduct.sku?.salesRelation.piecesDry ||
      chosenProduct.sku?.salesRelation.piecesMortar;

    const productPricePerM2 =
      (minPiecesPerMsq || 0) * chosenProduct.sku?.price?.pricePerPiece;

    const brickValues = getBrickOptions(
      value,
      Number(formatPrice(productPricePerM2)),
    );
    if (brickValues.id !== brickOptionResults?.id) {
      const resetDefaultValues: PriceWall | null = data && {
        ...data,
        mort: undefined,
        brickPrice: undefined,
        plaster: undefined,
      };
      setData(resetDefaultValues);
      setTriggerReset(true);
      setBrickOptionResults(brickValues);
    } else {
      setTriggerReset(false);
    }
  }
};

export default PriceCalculator;
